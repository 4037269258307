import React, {Fragment} from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  maxLength,
  email,
  SelectInput,
  translate,
} from 'react-admin';
import Title from '../Common/Header';
import {Edit} from '../Common/Admin';
import {hasRoles} from '../../utils/auth';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';

const isRequired = [required()];
const validateName = [required(), maxLength(255)];
const validateDescription = [maxLength(255)];
const validateEmail = [required(), maxLength(200), email()];
const mapStateToProps = ({admin, form}) => ({
  record: form['record-form'],
});

const EditUser = props => {
  const translate = props.translate;

  if (
    !hasRoles([
      'ROLE_CLIENT_ADMIN',
      'ROLE_EUROFOURS_TECHNICIAN',
      'ROLE_SUPERADMIN',
    ])
  ) {
    window.location.replace('/#/not-granted');
  }
  let redirect = 'show';
  if (hasRoles(['ROLE_EUROFOURS_TECHNICIAN'])) {
    if (props.record) {
      if (
        props.record.values.roles.includes('ROLE_USER') ||
        props.record.values.roles.includes('ROLE_EUROFOURS_TECHNICIAN')
      ) {
        window.location.replace('/#/not-granted');
      }
    }
    redirect = '/client-admins';
  }
  if (hasRoles(['ROLE_SUPERADMIN'])) {
    redirect = props.location.state.from;
    if (props.record) {
      if (props.record.values.roles.includes('ROLE_EUROFOURS_TECHNICIAN')) {
        return (
          <Edit
            title={<Title redirect={redirect} {...props} />}
            actions={<Fragment />}
            {...props}>
            <SimpleForm
              toolbar={<CustomToolbar props={props} />}
              redirect={redirect}>
              <TextInput source="firstName" validate={validateName} />
              <TextInput source="lastName" validate={validateName} />
              <TextInput source="email" type="email" validate={validateEmail} />
              <SelectInput
                source="countryCode"
                choices={[{id: 'FR'}, {id: 'EN'}]}
                optionText="id"
                validate={isRequired}
              />
              <SelectInput
                source="temperatureUnitPreference"
                label={translate(
                  'custom.commons.temperatureUnitPreference.label',
                )}
                choices={[
                  {
                    id: 'C',
                    name: `${translate(
                      'custom.commons.temperatureUnitPreference.celsius',
                    )} - C°`,
                  },
                  {
                    id: 'F',
                    name: `${translate(
                      'custom.commons.temperatureUnitPreference.fahrenheit',
                    )} - F°`,
                  },
                ]}
                validate={isRequired}
                style={{marginBottom: '2em'}}
              />
            </SimpleForm>
          </Edit>
        );
      }
    }
  }
  return (
    <Edit
      title={<Title redirect={redirect} {...props} />}
      actions={<Fragment />}
      undoable={false} // Warning : this props will break if we update to react-admin V4
      {...props}>
      <SimpleForm toolbar={<CustomToolbar props={props} />} redirect={redirect}>
        <TextInput source="firstName" validate={validateName} />
        <TextInput source="lastName" validate={validateName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source="society" validate={isRequired} />
        <TextInput
          source="description"
          multiline
          validate={validateDescription}
        />
        <TextInput source="phone" inputProps={{maxLength: 15}} />
        <TextInput source="street" validate={isRequired} />
        <TextInput source="city" validate={isRequired} />
        <TextInput
          source="zipcode"
          inputProps={{maxLength: 7}}
          validate={isRequired}
        />
        <TextInput source="country" validate={isRequired} />
        <SelectInput
          source="countryCode"
          choices={[{id: 'FR'}, {id: 'EN'}]}
          optionText="id"
          validate={isRequired}
        />
        <SelectInput
          source="temperatureUnitPreference"
          label={translate('custom.commons.temperatureUnitPreference.label')}
          choices={[
            {
              id: 'C',
              name: `${translate(
                'custom.commons.temperatureUnitPreference.celsius',
              )} - C°`,
            },
            {
              id: 'F',
              name: `${translate(
                'custom.commons.temperatureUnitPreference.fahrenheit',
              )} - F°`,
            },
          ]}
          validate={isRequired}
          style={{marginBottom: '2em'}}
        />
      </SimpleForm>
    </Edit>
  );
};

export default compose(
  connect(mapStateToProps, {}),
  withRouter,
  translate,
)(EditUser);
