import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {BooleanInput, LongTextInput, SimpleForm, TextField} from 'react-admin';
import {compose} from 'recompose';
import Title from '../Common/Header';
import {Edit} from '../Common/Admin';
import {withRouter} from 'react-router-dom';
import {SOFTWARE_UPDATES_PATH} from '../../constants';
import {CustomToolbar} from '../Common/Admin/CustomToolbar';

class SoftwareUpdatesEdit extends Component {
  render() {
    const {
      dispatch,
      location,
      match,
      staticContext,
      translate,
      ...rest
    } = this.props;

    return (
      <Edit
        actions={<Fragment />}
        basePath="/software-updates"
        id={decodeURIComponent(match.params.id)}
        location={location}
        match={match}
        resource="software_updates"
        undoable={false}
        title={
          <Title
            location={location}
            redirect={SOFTWARE_UPDATES_PATH}
            title="custom.components.software_updates.edit.title"
          />
        }
        {...rest}>
        <SimpleForm
          toolbar={<CustomToolbar showDelete={false} props={this.props} />}>
          <TextField source="version" />
          <BooleanInput options={{disabled: true}} source="beta" />
          <BooleanInput source="revoked" />
          <LongTextInput source="description" />
        </SimpleForm>
      </Edit>
    );
  }
}

export default compose(connect(), withRouter)(SoftwareUpdatesEdit);
